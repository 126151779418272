import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-462bb5f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "make-appointment" }
const _hoisted_2 = {
  style: {"margin-top":"32px"},
  class: "make-appointment__types make-appointment__types--mobile"
}
const _hoisted_3 = {
  style: {"margin-top":"24px"},
  class: "white-box"
}
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 0,
  class: "make-appointment__patients"
}
const _hoisted_6 = { class: "white-box" }
const _hoisted_7 = {
  style: {"margin-top":"24px"},
  class: "white-box"
}
const _hoisted_8 = { class: "make-appointment__slots-contact" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "make-appointment__slots-controls" }
const _hoisted_11 = {
  key: 1,
  style: {"margin-top":"80px"},
  class: "make-appointment__agreemments"
}
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = { class: "nfz-popup__description" }
const _hoisted_14 = { class: "nfz-popup__call-wrapper" }
const _hoisted_15 = { class: "nfz-popup__description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_button_group = _resolveComponent("a-button-group")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_tags = _resolveComponent("tags")!
  const _component_PlusCircleOutlined = _resolveComponent("PlusCircleOutlined")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_date_picker = _resolveComponent("a-date-picker")!
  const _component_slots = _resolveComponent("slots")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_PhoneOutlined = _resolveComponent("PhoneOutlined")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_ChangeDeclarationFacilityPopup = _resolveComponent("ChangeDeclarationFacilityPopup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_row, { type: "flex" }, {
      default: _withCtx(() => [
        _createVNode(_component_a_col, {
          lg: { span: 19 },
          md: { span: 19 },
          sm: { span: 24 },
          xs: { span: 24 }
        }, {
          default: _withCtx(() => [
            _createElementVNode("h2", null, [
              _createTextVNode(_toDisplayString(_ctx.t("MAKE_APPOINTMENT.HEADER")), 1 /* TEXT */),
              (_ctx.isNfzChoosen)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(_toDisplayString(_ctx.t("MAKE_APPOINTMENT.HEADER_NFZ")), 1 /* TEXT */)
                  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
                : _createCommentVNode("v-if", true)
            ]),
            _createElementVNode("p", null, _toDisplayString(_ctx.t(`MAKE_APPOINTMENT.${_ctx.translationType}.SUB_HEADER`)), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }),
    _withDirectives(_createVNode(_component_a_row, { type: "flex" }, {
      default: _withCtx(() => [
        _createVNode(_component_a_col, {
          xs: { span: 0 },
          sm: { span: 24 }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_button_group, {
              style: {"margin-top":"32px"},
              class: "make-appointment__types"
            }, {
              default: _withCtx(() => [
                (_ctx.isNfzChoosen)
                  ? (_openBlock(), _createBlock(_component_a_button, {
                      key: 0,
                      class: "make-appointment__type make-appointment__type--stationary",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showNfzPopup = true)),
                      type: "default"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t("VISIT.TYPE.STATIONARY")), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    }))
                  : (_openBlock(), _createBlock(_component_a_button, {
                      key: 1,
                      class: "make-appointment__type make-appointment__type--house",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeType('HOUSE'))),
                      type: _ctx.type == 'HOUSE' ? 'primary' : 'default'
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t("VISIT.TYPE.HOUSE")), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["type"])),
                _createVNode(_component_a_button, {
                  class: "make-appointment__type make-appointment__type--call",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeType('CALL'))),
                  type: _ctx.type == 'CALL' ? 'primary' : 'default'
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("VISIT.TYPE.CALL")), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["type"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_a_col, {
          xs: { span: 24 },
          sm: { span: 0 }
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_a_button, {
                class: "make-appointment__type make-appointment__type--house",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changeType('HOUSE'))),
                type: _ctx.type == 'HOUSE' ? 'primary' : 'default'
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("VISIT.TYPE.HOUSE")), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["type"]),
              _createVNode(_component_a_button, {
                class: "make-appointment__type make-appointment__type--call",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.changeType('CALL'))),
                type: _ctx.type == 'CALL' ? 'primary' : 'default'
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("VISIT.TYPE.CALL")), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["type"])
            ])
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 512 /* NEED_PATCH */), [
      [_vShow, _ctx.hasPermission(_ctx.actions.SHOW_APPOINTMENT_TYPES)]
    ]),
    (_ctx.type)
      ? (_openBlock(), _createBlock(_component_a_form, {
          key: 0,
          autocomplete: "off",
          ref: "makeAppointmentFormTemplate",
          model: _ctx.model,
          rules: _ctx.rules
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_a_row, { class: "white-box__row make-appointment__country" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, {
                    lg: 5,
                    md: 5,
                    sm: 5,
                    xs: 10,
                    class: "white-box__field-name"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("MAKE_APPOINTMENT.COUNTRY")), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_a_col, {
                    lg: { span: 13, offset: 1 },
                    md: { span: 13, offset: 1 },
                    sm: { span: 12, offset: 2 },
                    xs: { span: 12, offset: 2 },
                    class: "white-box__value"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "countryId" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            disabled: 
                  !_ctx.hasPermission(_ctx.actions.DISABLE_MAKE_APPOITNMENT_COUNTRY)
                ,
                            value: _ctx.model.countryId,
                            "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.countryId) = $event)),
                            "show-search": "",
                            loading: _ctx.loadingCountries.status === 'pending',
                            "default-active-first-option": false,
                            "option-filter-prop": "label",
                            placeholder: _ctx.t('MAKE_APPOINTMENT.CHOOSE_COUNTRY'),
                            style: {"width":"100%"},
                            "not-found-content": _ctx.t('NO_DATA'),
                            "filter-option": _ctx.filterCountryOption,
                            onChange: _ctx.getSlots
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countryOptions, (country, index) => {
                                return (_openBlock(), _createBlock(_component_a_select_option, {
                                  value: country.id,
                                  key: index
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(country.name), 1 /* TEXT */)
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]))
                              }), 128 /* KEYED_FRAGMENT */))
                            ]),
                            _: 1 /* STABLE */
                          }, 8 /* PROPS */, ["disabled", "value", "loading", "placeholder", "not-found-content", "filter-option", "onChange"])
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              (_ctx.type === 'HOUSE')
                ? (_openBlock(), _createBlock(_component_a_row, {
                    key: 0,
                    class: "white-box__row make-appointment__city"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, {
                        lg: 5,
                        md: 5,
                        sm: 5,
                        xs: 10,
                        class: "white-box__field-name"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t("MAKE_APPOINTMENT.CITY")), 1 /* TEXT */)
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_a_col, {
                        lg: { span: 13, offset: 1 },
                        md: { span: 13, offset: 1 },
                        sm: { span: 12, offset: 2 },
                        xs: { span: 12, offset: 2 },
                        class: "white-box__value"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "cityId" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_select, {
                                value: _ctx.model.cityId,
                                "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.cityId) = $event)),
                                "show-search": "",
                                loading: _ctx.loadingCities.status === 'pending',
                                "default-active-first-option": false,
                                placeholder: _ctx.t('MAKE_APPOINTMENT.CHOOSE_CITY'),
                                "option-filter-prop": "label",
                                style: {"width":"100%"},
                                "not-found-content": _ctx.t('NO_DATA'),
                                "filter-option": _ctx.filterCityOption,
                                onChange: _ctx.getSlots
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cityOptions, (city, index) => {
                                    return (_openBlock(), _createBlock(_component_a_select_option, {
                                      value: city.id,
                                      key: index
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(city.town), 1 /* TEXT */)
                                      ]),
                                      _: 2 /* DYNAMIC */
                                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]))
                                  }), 128 /* KEYED_FRAGMENT */))
                                ]),
                                _: 1 /* STABLE */
                              }, 8 /* PROPS */, ["value", "loading", "placeholder", "not-found-content", "filter-option", "onChange"])
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  }))
                : _createCommentVNode("v-if", true),
              _createVNode(_component_a_row, { class: "white-box__row make-appointment__language" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, {
                    lg: 5,
                    md: 5,
                    sm: 5,
                    xs: 10,
                    class: "white-box__field-name"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("MAKE_APPOINTMENT.LANGUAGE")), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_a_col, {
                    lg: { span: 13, offset: 1 },
                    md: { span: 13, offset: 1 },
                    sm: { span: 12, offset: 2 },
                    xs: { span: 12, offset: 2 },
                    class: "white-box__value"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "languageId" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: _ctx.model.languageId,
                            "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.languageId) = $event)),
                            "show-search": "",
                            disabled: 
                  !_ctx.hasPermission(_ctx.actions.DISABLE_MAKE_APPOITNMENT_LANGUAGE)
                ,
                            loading: _ctx.loadingLanguages.status === 'pending',
                            "default-active-first-option": false,
                            placeholder: _ctx.t('MAKE_APPOINTMENT.CHOOSE_LANGUAGE'),
                            "option-filter-prop": "label",
                            style: {"width":"100%"},
                            "not-found-content": _ctx.t('NO_DATA'),
                            "filter-option": _ctx.filterLanguageOption,
                            onChange: _ctx.getSlots
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.languageOptions, (language, index) => {
                                return (_openBlock(), _createBlock(_component_a_select_option, {
                                  value: language.id,
                                  key: index
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(language.name), 1 /* TEXT */)
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]))
                              }), 128 /* KEYED_FRAGMENT */))
                            ]),
                            _: 1 /* STABLE */
                          }, 8 /* PROPS */, ["value", "disabled", "loading", "placeholder", "not-found-content", "filter-option", "onChange"])
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_a_row, { class: "white-box__row make-appointment__specialization" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, {
                    lg: 5,
                    md: 5,
                    sm: 5,
                    xs: 10,
                    class: "white-box__field-name"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("MAKE_APPOINTMENT.SPECIALIZATION")), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_a_col, {
                    lg: { span: 13, offset: 1 },
                    md: { span: 13, offset: 1 },
                    sm: { span: 12, offset: 2 },
                    xs: { span: 12, offset: 2 },
                    class: "white-box__value"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "specializationId" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: _ctx.model.specializationId,
                            "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model.specializationId) = $event)),
                            "show-search": "",
                            loading: _ctx.loadingSpecializations.status === 'pending',
                            "default-active-first-option": false,
                            placeholder: _ctx.t('MAKE_APPOINTMENT.CHOOSE_SPECIALIZATION'),
                            "option-filter-prop": "label",
                            style: {"width":"100%"},
                            "not-found-content": _ctx.t('NO_DATA'),
                            "filter-option": _ctx.filterSpecializationOption,
                            onChange: _ctx.onChangeSpecialization
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.specializationOptions, (spec, index) => {
                                return (_openBlock(), _createBlock(_component_a_select_option, {
                                  value: spec.id,
                                  key: index
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(spec.name), 1 /* TEXT */)
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]))
                              }), 128 /* KEYED_FRAGMENT */))
                            ]),
                            _: 1 /* STABLE */
                          }, 8 /* PROPS */, ["value", "loading", "placeholder", "not-found-content", "filter-option", "onChange"])
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              (_ctx.type === 'CALL')
                ? (_openBlock(), _createBlock(_component_a_row, {
                    key: 1,
                    class: "white-box__row make-appointment__communication-type"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, {
                        lg: 5,
                        md: 5,
                        sm: 5,
                        xs: 10,
                        class: "white-box__field-name"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", {
                            innerHTML: _ctx.t('MAKE_APPOINTMENT.COMMUNICATION_TYPE')
                          }, null, 8 /* PROPS */, _hoisted_4)
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_a_col, {
                        lg: { span: 13, offset: 1 },
                        md: { span: 13, offset: 1 },
                        sm: { span: 12, offset: 2 },
                        xs: { span: 12, offset: 2 },
                        class: "white-box__value"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "communicationTypes" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_tags, {
                                value: _ctx.model.communicationTypes,
                                "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.model.communicationTypes) = $event)),
                                data: _ctx.communicationTypes,
                                mode: "single",
                                "empty-text": _ctx.t('NO_DATA'),
                                onChange: _cache[10] || (_cache[10] = ($event: any) => (_ctx.reloadSlots(_ctx.model.communicationTypes)))
                              }, null, 8 /* PROPS */, ["value", "data", "empty-text"])
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  }))
                : _createCommentVNode("v-if", true)
            ]),
            (_ctx.type === 'HOUSE')
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_a_row, {
                    style: {"margin-top":"66px"},
                    type: "flex"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, {
                        lg: { span: 16 },
                        md: { span: 16 },
                        sm: { span: 16 },
                        xs: { span: 12 }
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("h2", null, _toDisplayString(_ctx.t("MAKE_APPOINTMENT.PATIENTS")), 1 /* TEXT */)
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_a_col, {
                        lg: { span: 8 },
                        md: { span: 8 },
                        sm: { span: 8 },
                        xs: { span: 12 }
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_button, {
                            class: "make-appointment__add-patient",
                            onClick: _ctx.addPatient,
                            type: "link"
                          }, {
                            icon: _withCtx(() => [
                              _createVNode(_component_PlusCircleOutlined)
                            ]),
                            default: _withCtx(() => [
                              _createTextVNode(" " + _toDisplayString(_ctx.t("MAKE_APPOINTMENT.ADD_PATIENTS")), 1 /* TEXT */)
                            ]),
                            _: 1 /* STABLE */
                          }, 8 /* PROPS */, ["onClick"])
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createElementVNode("div", _hoisted_6, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.patients, (patient, index) => {
                      return (_openBlock(), _createElementBlock("div", { key: index }, [
                        _createVNode(_component_a_form, {
                          ref: 
                (el) => {
                  if (el) _ctx.patientsFormTemplate[index] = el;
                }
              ,
                          model: patient.model,
                          autocomplete: "off",
                          rules: patient.rules
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_row, {
                              gutter: [
                  { xs: 0, sm: 0, md: 0, lg: 0 },
                  { xs: 16, sm: 0, md: 0, lg: 0 },
                ],
                              class: "white-box__row make-appointment__patient"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_col, {
                                  lg: 5,
                                  md: 5,
                                  sm: 6,
                                  xs: 7,
                                  class: "white-box__field-name"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.t("MAKE_APPOINTMENT.PATIENT")) + " " + _toDisplayString(index + 1), 1 /* TEXT */)
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1024 /* DYNAMIC_SLOTS */),
                                _createVNode(_component_a_col, {
                                  lg: { span: 7, offset: 1 },
                                  md: { span: 7, offset: 1 },
                                  sm: { span: 6, offset: 1 },
                                  xs: { span: 15, offset: 2 },
                                  class: "white-box__value make-appointment__patient-value make-appointment__patient-value--type"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_form_item, { name: "type" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_a_select, {
                                          value: patient.model.type,
                                          "onUpdate:value": ($event: any) => ((patient.model.type) = $event),
                                          "default-active-first-option": false,
                                          onChange: ($event: any) => (_ctx.onPatientChange(patient, index)),
                                          placeholder: "Wybierz typ pacjenta",
                                          "option-filter-prop": "label",
                                          style: {"width":"100%"},
                                          "not-found-content": _ctx.t('NO_DATA')
                                        }, {
                                          default: _withCtx(() => [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.patientTypeOptions, (patientType, patientTypeIndex) => {
                                              return (_openBlock(), _createBlock(_component_a_select_option, {
                                                value: patientType,
                                                key: patientTypeIndex
                                              }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(_ctx.t(`PATIENT.TYPE.${patientType}`)), 1 /* TEXT */)
                                                ]),
                                                _: 2 /* DYNAMIC */
                                              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]))
                                            }), 128 /* KEYED_FRAGMENT */))
                                          ]),
                                          _: 2 /* DYNAMIC */
                                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "onUpdate:value", "onChange", "not-found-content"])
                                      ]),
                                      _: 2 /* DYNAMIC */
                                    }, 1024 /* DYNAMIC_SLOTS */)
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1024 /* DYNAMIC_SLOTS */),
                                _createVNode(_component_a_col, {
                                  lg: { span: 7, offset: 1 },
                                  md: { span: 7, offset: 1 },
                                  sm: { span: 6, offset: 1 },
                                  xs: { span: 15, offset: 9 },
                                  class: "white-box__value make-appointment__patient-value make-appointment__patient-value--age"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_form_item, { name: "age" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_a_select, {
                                          disabled: patient.model.type === 'ADULT',
                                          value: patient.model.age,
                                          "onUpdate:value": ($event: any) => ((patient.model.age) = $event),
                                          "default-active-first-option": false,
                                          placeholder: _ctx.t('MAKE_APPOINTMENT.CHOOSE_AGE_RANGE'),
                                          "option-filter-prop": "label",
                                          style: {"width":"100%"},
                                          "not-found-content": _ctx.t('NO_DATA')
                                        }, {
                                          default: _withCtx(() => [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.patientAgeRangeOptions, (ageRange, ageRangeIndex) => {
                                              return (_openBlock(), _createBlock(_component_a_select_option, {
                                                value: ageRange.value,
                                                key: ageRangeIndex
                                              }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(ageRange.label), 1 /* TEXT */)
                                                ]),
                                                _: 2 /* DYNAMIC */
                                              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]))
                                            }), 128 /* KEYED_FRAGMENT */))
                                          ]),
                                          _: 2 /* DYNAMIC */
                                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["disabled", "value", "onUpdate:value", "placeholder", "not-found-content"])
                                      ]),
                                      _: 2 /* DYNAMIC */
                                    }, 1024 /* DYNAMIC_SLOTS */)
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1024 /* DYNAMIC_SLOTS */),
                                _createVNode(_component_a_col, {
                                  lg: { span: 3 },
                                  md: { span: 3 },
                                  sm: { span: 4 },
                                  xs: { span: 24 },
                                  class: "white-box__actions"
                                }, {
                                  default: _withCtx(() => [
                                    _withDirectives(_createVNode(_component_a_button, {
                                      type: "link",
                                      onClick: ($event: any) => (_ctx.removePatient(index)),
                                      class: "white-box__red-action"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.t("MAKE_APPOINTMENT.REMOVE_PATIENT")), 1 /* TEXT */)
                                      ]),
                                      _: 2 /* DYNAMIC */
                                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]), [
                                      [_vShow, index != 0]
                                    ])
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1024 /* DYNAMIC_SLOTS */)
                              ]),
                              _: 2 /* DYNAMIC */
                            }, 1024 /* DYNAMIC_SLOTS */)
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["model", "rules"])
                      ]))
                    }), 128 /* KEYED_FRAGMENT */))
                  ])
                ]))
              : _createCommentVNode("v-if", true),
            _createVNode(_component_a_row, {
              style: {"margin-top":"66px"},
              type: "flex"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, {
                  lg: { span: 16 },
                  md: { span: 20 },
                  sm: { span: 22 },
                  xs: { span: 24 }
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("h2", null, _toDisplayString(_ctx.t("MAKE_APPOINTMENT.HEADER_2")), 1 /* TEXT */),
                    _createElementVNode("p", null, _toDisplayString(_ctx.t("MAKE_APPOINTMENT.SUB_HEADER_2")), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_a_row, { class: "white-box__row make-appointment__date" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, {
                    lg: 5,
                    md: 5,
                    sm: 5,
                    xs: 10,
                    class: "white-box__field-name"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("MAKE_APPOINTMENT.DATE")), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_a_col, {
                    lg: { span: 13, offset: 1 },
                    md: { span: 13, offset: 1 },
                    sm: { span: 12, offset: 2 },
                    xs: { span: 12, offset: 2 },
                    class: "white-box__value"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "date" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_date_picker, {
                            value: _ctx.model.date,
                            "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.model.date) = $event)),
                            style: {"width":"100%"},
                            format: "DD.MM.YYYY",
                            "disabled-date": _ctx.disabledDate,
                            "allow-clear": false,
                            "input-read-only": true,
                            disabled: _ctx.loadingSlots.status === 'pending',
                            onChange: _ctx.getSlots
                          }, null, 8 /* PROPS */, ["value", "disabled-date", "disabled", "onChange"])
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_a_row, { class: "white-box__row make-appointment__slots" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, {
                    lg: 5,
                    md: 5,
                    sm: 5,
                    xs: 10,
                    class: "white-box__field-name"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("MAKE_APPOINTMENT.SLOTS")), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_a_col, {
                    lg: { span: 13, offset: 1 },
                    md: { span: 13, offset: 1 },
                    sm: { span: 12, offset: 2 },
                    xs: { span: 12, offset: 2 },
                    class: "white-box__value"
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("div", _hoisted_8, [
                        _createElementVNode("span", {
                          innerHTML: _ctx.t('MAKE_APPOINTMENT.SLOTS_CONTACT')
                        }, null, 8 /* PROPS */, _hoisted_9)
                      ], 512 /* NEED_PATCH */), [
                        [_vShow, !_ctx.showSlots]
                      ]),
                      _withDirectives(_createElementVNode("div", _hoisted_10, [
                        _createVNode(_component_a_row, {
                          justify: "space-between",
                          gutter: [0, 8]
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, {
                              xs: 24,
                              sm: 24,
                              md: 11,
                              lg: 8
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_button, {
                                  class: "make-appointment__slots-prev-day",
                                  onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.changeDate('prev'))),
                                  disabled: 
                      _ctx.loadingSlots.status === 'pending' || _ctx.disabledPrevDate
                    
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.t("MAKE_APPOINTMENT.PREV_DAY")), 1 /* TEXT */)
                                  ]),
                                  _: 1 /* STABLE */
                                }, 8 /* PROPS */, ["disabled"])
                              ]),
                              _: 1 /* STABLE */
                            }),
                            _createVNode(_component_a_col, {
                              xs: 24,
                              sm: 24,
                              md: 11,
                              lg: 8
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_button, {
                                  class: "make-appointment__slots-next-day",
                                  onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.changeDate('next'))),
                                  disabled: _ctx.loadingSlots.status === 'pending'
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.t("MAKE_APPOINTMENT.NEXT_DAY")), 1 /* TEXT */)
                                  ]),
                                  _: 1 /* STABLE */
                                }, 8 /* PROPS */, ["disabled"])
                              ]),
                              _: 1 /* STABLE */
                            })
                          ]),
                          _: 1 /* STABLE */
                        })
                      ], 512 /* NEED_PATCH */), [
                        [_vShow, _ctx.showSlots]
                      ]),
                      _withDirectives(_createVNode(_component_a_form_item, { name: "slot" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_slots, {
                            value: _ctx.model.slotIndex,
                            "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.model.slotIndex) = $event)),
                            loading: _ctx.loadingSlots.status === 'pending',
                            data: _ctx.slots
                          }, null, 8 /* PROPS */, ["value", "loading", "data"])
                        ]),
                        _: 1 /* STABLE */
                      }, 512 /* NEED_PATCH */), [
                        [_vShow, _ctx.showSlots]
                      ])
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              })
            ]),
            (_ctx.type === 'CALL')
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _createVNode(_component_a_row, { type: "flex" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, {
                        lg: { span: 16 },
                        md: { span: 20 },
                        sm: { span: 22 },
                        xs: { span: 24 }
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("h2", null, _toDisplayString(_ctx.t("MAKE_APPOINTMENT.AGGREMENTS")), 1 /* TEXT */)
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_a_row, {
                    class: "make-appointment__agreemment make-appointment__agreemment--intra-regulation",
                    type: "flex"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, {
                        lg: { span: 16 },
                        md: { span: 20 },
                        sm: { span: 22 },
                        xs: { span: 24 }
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "intraRegulationAgreement" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_checkbox, {
                                checked: _ctx.model.intraRegulationAgreement,
                                "onUpdate:checked": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.model.intraRegulationAgreement) = $event))
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("span", {
                                    innerHTML: 
                    _ctx.t(
                      `MAKE_APPOINTMENT.${_ctx.translationType}.INTRA_REGULATION_AGREEMENT`
                    )
                  
                                  }, null, 8 /* PROPS */, _hoisted_12)
                                ]),
                                _: 1 /* STABLE */
                              }, 8 /* PROPS */, ["checked"])
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _withDirectives(_createVNode(_component_a_row, {
                    class: "make-appointment__agreemment make-appointment__agreemment--marketing",
                    type: "flex"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, {
                        lg: { span: 16 },
                        md: { span: 20 },
                        sm: { span: 22 },
                        xs: { span: 24 }
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "marketingAgreement" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_checkbox, {
                                checked: _ctx.model.marketingAgreement,
                                "onUpdate:checked": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.model.marketingAgreement) = $event))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("MAKE_APPOINTMENT.MARKETING_AGREEMENT")), 1 /* TEXT */)
                                ]),
                                _: 1 /* STABLE */
                              }, 8 /* PROPS */, ["checked"])
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  }, 512 /* NEED_PATCH */), [
                    [_vShow, _ctx.hasPermission(_ctx.actions.SHOW_MARKETING_AGREEMENT)]
                  ]),
                  _withDirectives(_createVNode(_component_a_row, {
                    class: "make-appointment__agreemment make-appointment__agreemment--trade",
                    type: "flex"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, {
                        lg: { span: 16 },
                        md: { span: 20 },
                        sm: { span: 22 },
                        xs: { span: 24 }
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "tradeAgreement" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_checkbox, {
                                checked: _ctx.model.tradeAgreement,
                                "onUpdate:checked": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.model.tradeAgreement) = $event))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("MAKE_APPOINTMENT.TRADE_AGREEMENT")), 1 /* TEXT */)
                                ]),
                                _: 1 /* STABLE */
                              }, 8 /* PROPS */, ["checked"])
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  }, 512 /* NEED_PATCH */), [
                    [_vShow, _ctx.hasPermission(_ctx.actions.SHOW_TRADE_AGREEMENT)]
                  ])
                ]))
              : _createCommentVNode("v-if", true),
            _createVNode(_component_a_row, {
              style: {"margin":"64px 0 42px 0"},
              class: "make-appointment__footer",
              type: "flex"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, {
                  lg: { span: 16 },
                  md: { span: 20 },
                  sm: { span: 22 },
                  xs: { span: 24 }
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      loading: _ctx.loading,
                      onClick: _ctx.submit,
                      class: "make-appointment__submit",
                      disabled: !_ctx.valid[0] || !_ctx.valid[1],
                      type: "primary"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t("MAKE_APPOINTMENT.SUBMIT")), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["loading", "onClick", "disabled"]),
                    _createVNode(_component_a_button, { class: "make-appointment__cancel" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_router_link, { to: "/" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t("MAKE_APPOINTMENT.CANCEL")), 1 /* TEXT */)
                          ]),
                          _: 1 /* STABLE */
                        })
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["model", "rules"]))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_a_modal, {
      class: "nfz-popup",
      visible: _ctx.showNfzPopup,
      "onUpdate:visible": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.showNfzPopup) = $event)),
      closable: false,
      width: 560
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_a_button, {
          key: "submit",
          type: "primary",
          class: "nfz-popup__close",
          onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.showNfzPopup = !_ctx.showNfzPopup))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("MAKE_APPOINTMENT.MODAL_NFZ.CLOSE")), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        })
      ]),
      default: _withCtx(() => [
        _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.t("MAKE_APPOINTMENT.MODAL_NFZ.DESCRIPTION_1")), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_14, [
          _createVNode(_component_a_button, {
            type: "primary",
            size: "large",
            shape: "round",
            class: "nfz-popup__call",
            href: "tel:48226887925"
          }, {
            icon: _withCtx(() => [
              _createVNode(_component_PhoneOutlined),
              _createTextVNode(" " + _toDisplayString(_ctx.t("MAKE_APPOINTMENT.MODAL_NFZ.PHONE")), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.t("MAKE_APPOINTMENT.MODAL_NFZ.DESCRIPTION_2")), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["visible"]),
    (
        _ctx.isNfzChoosen &&
        _ctx.hasPermission(_ctx.actions.SHOW_CHANGE_DECLARATION_FACILITY_POPUP)
      )
      ? (_openBlock(), _createBlock(_component_ChangeDeclarationFacilityPopup, {
          key: 1,
          modelValue: _ctx.changeDeclarationFacilityPopupVisible,
          "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.changeDeclarationFacilityPopupVisible) = $event))
        }, null, 8 /* PROPS */, ["modelValue"]))
      : _createCommentVNode("v-if", true)
  ]))
}